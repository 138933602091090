@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fix for iOS Safari viewport height issues */
html {
  height: -webkit-fill-available;
  background-color: #261e26;
  overflow: hidden;
}

body {
  min-height: 100vh;
  /* Mobile viewport bug fix */
  min-height: -webkit-fill-available;
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  font-family: 'gg sans', 'Noto Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'gg sans';
}

.title {
  font-family: 'Noto Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

#root {
  min-height: 100vh;
  /* Mobile viewport bug fix */
  min-height: -webkit-fill-available;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #261e26;
  display: flex;
  justify-content: center;
  align-items: center;
}

@font-face {
  font-family: 'gg sans';
  src: url('./fonts/ggsans-vf.ttf') format('truetype'),
       url('./fonts/ggsans-vf.woff2') format('woff2'),
       url('./fonts/ggsans-vf.woff') format('woff');
}

